/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useContext, useRef } from 'react';
import OrderDetails from './OrderDetails';
import NewOrder from './NewOrder';
import './index.css';
import { Layout, Table, Typography, Button, FloatButton, Input, Space, ConfigProvider, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import ThemeContext from './context';
const { Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;
const backColor = '#b7dfeb'

function Main(context) {
  const [orderDetails, setOrderDetails] = useState({});
  const { setModalVisible } = useContext(ThemeContext)

  async function handleOk(e) {
    let orders = await axios.get(`https://lk.transcargo-group.com/basezip/hs/zayavki/${context.vesselCode}`, { auth: context.getAuth() });
    // console.log('orders', { orders });
    context.setOrders(orders.data);
    setModalVisible(false);
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Введите наименование`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Найти
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters)
              confirm()
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Сброс
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Номер',
      dataIndex: 'code',
      key: 'code',
      ...getColumnSearchProps('code'),
      render: (text, record) => (
        (record.status === 'Заявка удалена') ? <Text type="danger">{text}</Text> :
          (record.status === 'Заказ получен') ? <Text type="success">{text}</Text> :
            (record.status === 'Заказ отслеживание доставки') ? <Text strong type="warning">{text}</Text> :
              <Text> {text} </Text>
      ),
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (text, record) => (
        (record.status === 'Заявка удалена') ? <Text type="danger">{text}</Text> :
        (record.status === 'Заказ получен' && record.changed === '1') ? <div><Text type="success">{text}</Text><Tag color="warning">Скорректирован</Tag></div> :  
        (record.status === 'Заказ получен') ? <Text type="success">{text}</Text> :
            (record.status === 'Заказ отслеживание доставки' && record.changed === '1') ? <div><Text strong type="warning">{text} <Tag color="warning">Скорректирован</Tag> </Text></div> :
            (record.status === 'Заказ отслеживание доставки') ? <Text strong type="warning">{text}</Text> :
            (record.changed === '1') ?  <div> <Text> {text} </Text> <Tag color="warning">Скорректирован</Tag> </div> :
              <Text> {text} </Text>
      ),
    },
    {
      title: 'Тип оборудования',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSearchProps('type'),
      render: (text, record) => (
        (record.status === 'Заявка удалена') ? <Text type="danger">{text}</Text> :
          (record.status === 'Заказ получен') ? <Text type="success">{text}</Text> :
            (record.status === 'Заказ отслеживание доставки') ? <Text strong type="warning">{text}</Text> :
              <Text> {text} </Text>
      ),
    },
    {
      title: 'Статус документа',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        (record.status === 'Заявка удалена') ? <Text type="danger">{text}</Text> :
          (record.status === 'Заказ получен') ? <Text type="success">{text}</Text> :
            (record.status === 'Заказ отслеживание доставки') ? <Text strong type="warning">{text}</Text> :
              <Text> {text} </Text>
      ),

      filters: [
        {
          text: 'Заявка создана',
          value: 'Заявка создана',
        },
        {
          text: 'Заявка передана в ОЗ',
          value: 'Заявка передана в ОЗ',
        },
        {
          text: 'Заявка удалена',
          value: 'Заявка удалена',
        },
        {
          text: 'Заказ отслеживание доставки',
          value: 'Заказ отслеживание доставки',
        },
        {
          text: 'Заказ получен',
          value: 'Заказ получен',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: 'Категория судна',
      dataIndex: 'catvessel',
      key: 'catvessel',
      render: (text, record) => (
        (record.status === 'Заявка удалена') ? <Text type="danger">{text}</Text> :
          (record.status === 'Заказ получен') ? <Text type="success">{text}</Text> :
            (record.status === 'Заказ отслеживание доставки') ? <Text strong type="warning">{text}</Text> :
              <Text> {text} </Text>
      ),

      filters: [
        {
          text: '1 квартал расходное снабжение',
          value: '1 квартал расходное снабжение',
        },
        {
          text: '2 квартал расходное снабжение',
          value: '2 квартал расходное снабжение',
        },
        {
          text: '3 квартал расходное снабжение',
          value: '3 квартал расходное снабжение',
        },
        {
          text: '4 квартал расходное снабжение',
          value: '4 квартал расходное снабжение',
        },
        {
          text: 'Аварийные (авария, либо замечания ИГПК,  РС)',
          value: 'Аварийные (авария, либо замечания ИГПК,  РС)',
        },
        {
          text: 'Плановое ТО судовых механизмов',
          value: 'Плановое ТО судовых механизмов',
        },
        {
          text: 'Ремонт',
          value: 'Ремонт',
        },
        {
          text: 'Плановые проверки судового оборудования',
          value: 'Плановые проверки судового оборудования',
        },
        {
          text: 'Ремонтные работы выполняемые сторонними...',
          value: 'Ремонтные работы выполняемые сторонними организациями',
        },
      ],
      onFilter: (value, record) => record.catvessel.indexOf(value) === 0,
    },
    {
      title: 'Поставщик',
      dataIndex: 'provider',
      key: 'provider',
      ...getColumnSearchProps('provider'),
      render: (text, record) => (
        (record.status === 'Заявка удалена') ? <Text type="danger">{text}</Text> :
          (record.status === 'Заказ получен') ? <Text type="success">{text}</Text> :
            (record.status === 'Заказ отслеживание доставки') ? <Text strong type="warning">{text}</Text> :
              <Text> {text} </Text>
      ),
    }
  ];
  
  return (
    <div style={{ height: '50vh'}}>
      <Layout>
        <Header style={{ backgroundColor: backColor }}/>
        <Layout>
          <Sider style={{ backgroundColor: backColor }} width='100'/>
          <Content>
            <Space wrap>
              <Button style={{ marginLeft: '16px' }} type="primary" onClick={() => setModalVisible(true)}>
                Создать заявку
              </Button>
              <Button size="delault" onClick={() => handleOk()}>Обновить</Button>
            </Space>
            <NewOrder
              setOrders={(o) => context.setOrders(o)}
              // setVisible={v => setModalVisible(v)}
              // isVisible={() => modalVisible}
              vendors={context.vendors}
              components={context.components}
              vesselCode={context.vesselCode}
              auth={context.getAuth()}
              catvessel={context.catvessel}
              spareParts={context.spareParts}
              expendableSupply={context.expendableSupply}
              services={context.services}
            />
            <Title style={{ paddingLeft: '16px' }}>Заявки</Title>
            <ConfigProvider
              theme={{
                  "components": {
                    "Table": {
                      "rowHoverBg": "#bae0ffb4",
                      "rowExpandedBg" : "#9aa9b555",
                    }
                  }
                 }}
            >
            <Table
              dataSource={context.orders}
              expandedRowRender={
                record => {
                  return orderDetails && orderDetails[record.key] &&
                    (<OrderDetails
                      details={orderDetails[record.key]}
                      statuskey={record.statuskey}
                      auth={context.getAuth()}
                      vesselCode={context.vesselCode}
                      setOrders={(o) => context.setOrders(o)}
                      setVisible={v => setModalVisible(v)} />)
                }
              }
              onExpand={
                async (expanded, record) => {
                  if (!expanded) return;
                  if (orderDetails[record.key]) return;

                  let details = await axios.get(`https://lk.transcargo-group.com/basezip/hs/order/${record.key}`, { auth: context.getAuth() });
                  console.log({details});
                  setOrderDetails(prevState => ({ ...prevState, [record.key]: details }));
                }
              }
              columns={columns}
              pagination={{
                defaultPageSize: '25',
                showSizeChanger: true,
                pageSizeOptions: [15, 25, 50, 100]
              }}
              sticky={true}
            />
            </ConfigProvider>
          </Content>
          <Sider style={{ backgroundColor: backColor }} width='100'/>
        </Layout>
        <Footer style={{ backgroundColor: backColor }}/>

        <FloatButton.BackTop>
          {/* <div style={style}>Вверх</div> */}
        </FloatButton.BackTop>
      </Layout>
    </div>
  );
}

export default Main;
